<template>
    <v-card hover>   
        <v-card-title style="background-color:#4E87DD; color:#FFFFFF; height:70px;">   
            <v-row no-gutters>
                <v-col class="pt-1 pl-4 text-h5 font-weight-bold">
                    연결목록 : {{callerMdn | phoneNum}}
                </v-col>
                <v-col class="text-right mr-0 mt-0 pt-0">
                    <v-btn text @click="closePopup"><span class="white--text text-h6">X</span></v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text class="pb-0 pl-7 pr-7 pt-5 mb-5">
            <v-card outlined>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr style="background-color:#F4F6F8; height:78px; color:#333333; font-weight:600;">
                            <th class="text-left text-h6 pl-6">
                                순서
                            </th>
                            <th class="text-left text-h6 pl-6">
                                시작 시간
                            </th>
                            <th class="text-left text-h6">
                                ARS메뉴
                            </th>
                            <th class="text-left text-h6">
                                수신자
                            </th>
                            <th class="text-left text-h6">
                                통화결과
                            </th>
                            <th class="text-left text-h6" v-if="mainAdminYn == 'Y'">
                                녹취
                            </th>
                        </tr>
                        </thead>
                        <tbody style="color:#555555;">
                        <template v-for="(statItem, statIdx) in svcCallList">
                            <tr style="height:68px;" :key="statIdx">
                                <td class="text-left pl-6" style="font-size:20px;">{{statIdx + 1}}</td>
                                <td class="text-left pl-6" style="font-size:20px;">{{statItem.call_start_dt | dateSec}}</td>
                                <td class="text-left" style="font-size:20px;">{{statItem.related_menu_name}}</td>
                                <td class="text-left" style="font-size:20px;">{{statItem.user_name}}</td>
                                <td class="text-left" style="font-size:20px;">{{callResultText(statItem.call_result)}}</td>
                                <td class="text-center pr-6" style="font-size:20px;" v-if="mainAdminYn == 'Y'">
                                    <v-btn small v-if="mainAdminYn == 'Y' && statItem.record_yn == 'Y' && statItem.record_result == 'DONE' && statItem.record_cont_seqno > 0" @click="downloadRecord(statItem)" outlined color="green">다운로드</v-btn>
                                    <span v-else>-</span>
                                </td>
                            </tr>
                        </template>
                        <tr style="height:69px;" v-if="!svcCallList || svcCallList.length == 0">
                            <td class="text-center" style="font-size:20px;" colspan="5">조회된 결과가 없습니다</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-card-text>

        <v-card-actions class="ma-0 pa-0">
            <v-row no-gutters>
                <v-col cols="12" class="pr-5 pb-6 pt-0 text-right">
                    <v-btn class="ma-2" width="130px" color="primary" v-on:click="closePopup()"><span style="color:#eeeeee; font-size:1.4em;">확인</span></v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
    
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import svcHistApi from "@/api/svc_hist";

export default {
    data: () => ({
    }),
    computed: {
        ...mapGetters({
            mainAdminYn: 'login/getMainAdminYn',
        }),
        ...mapState('svc_hist_svc', {
            svcCallList: state => state.svcCallList,
        }),
        callerMdn() {
            let ret = ''
            if(this.svcCallList && this.svcCallList.length > 0) {
                ret = this.svcCallList[0].remote_mdn
            }
            return ret
        }
    },    
    mounted() {
    },
    methods: {
        closePopup() { 
            console.log('popup_svc_call_list.closePopup()')
            this.$emit('cancel')
        },
        callResultText (c) {
            if(c == 'SUCC') {
                return '통화'
            } else if(c == 'MISS') {
                return '미응답'
            } else if(c == 'REJECT') {
                return '수신거부'
            }
            return c
        },
        receiveText (c) {
            let ret = ''

            if(c.related_menu_name && c.user_name) {
                ret = c.related_menu_name + ' ( ' + c.user_name + ' )'
            } else if(!c.related_menu_name && c.user_name) {
                ret = c.user_name
            } else if(c.related_menu_name && !c.user_name) {
                ret = c.related_menu_name
            }
            return ret
        },
        downloadRecord(item) {
            if(!item || !item.record_cont_seqno) { return }
            let va = this
            va.$store.dispatch('openConfirm', {
                message: '녹취 데이터를 다운로드 하시겠습니까?',
                va: va,
                okCb: function(va) {
                    svcHistApi.downloadRecord({seq: item.record_cont_seqno, tm: item.call_start_dt, mdn: va.callerMdn })
                }
            })
        },
    }
}
</script>
